// @components
import Image from "next/image";

export function NftCard({ img, title }: { img: string; title: string }) {
  return (
    <div className="group">
      <Image
        src={img}
        alt={`image of ${title}`}
        width={512}
        height={512}
        className="w-full h-[300px] object-cover object-center"
      />
      <div className="relative gap-4 p-4">
        <h3 className="text-slate-950 dark:text-slate-50 font-semibold w-max left-1 md:group-hover:left-1 transition-all duration-300 translate-x-0 md:group-hover:translate-x-0 relative md:left-1/2 md:-translate-x-1/2 text-xl">
          {title}
        </h3>
        <button
          aria-label="link to nft"
          className="absolute right-4 md:right-8 md:group-hover:right-4 opacity-100 top-1/2 -translate-y-1/2 md:opacity-0 md:group-hover:opacity-100 md:duration-300"
        >
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            strokeWidth="2.5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="currentColor"
            className="text-slate-950 dark:text-slate-50"
          >
            <path
              d="M6.00005 19L19 5.99996M19 5.99996V18.48M19 5.99996H6.52005"
              stroke="currentColor"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
}
