"use client";

import * as React from "react";

// @components
import Link from "next/link";
import Slider from "react-slick";
import { Heading, NftCard } from "@/components";

export function Nfts() {
  const sliderRef = React.useRef<any>(null);

  const settings = {
    fade: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
  };

  return (
    <section
      data-name="nfts"
      className="max-w-5xl mx-auto border-x border-b border-slate-100 dark:border-slate-900"
    >
      <div className="py-8 md:pt-8 md:pb-12 lg:pt-12 lg:pb-16 border-b border-slate-100 dark:border-slate-900 relative">
        <Heading backdrop="NFTs">NFT Collections</Heading>
      </div>
      <Slider ref={sliderRef} {...settings}>
        <div className="!grid sm:grid-cols-2 overflow-hidden grid-cols-1 lg:grid-cols-3 gap-10 relative">
          <span
            aria-hidden="true"
            className="border-t border-slate-100 dark:border-slate-900 block w-full absolute left-0 bottom-[60px]"
          />
          <Link
            href="https://app.mintra.ai/collection/0x45914a66d6fed5501275ab5a9842f2fb232ca68e"
            target="_blank"
            className="group lg:border-r relative lg:border-slate-100 dark:border-slate-900"
          >
            <NftCard img="/pls-punks-v1.png" title="PLS Punks v1" />
            <span
              aria-hidden="true"
              className="border-r border-slate-100 dark:border-slate-900 hidden sm:block lg:hidden h-[250%] absolute right-0 top-0"
            />
            <span
              aria-hidden="true"
              className="border-r border-slate-100 dark:border-slate-900 hidden sm:block lg:hidden h-[250%] absolute -right-10 top-0"
            />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 hidden sm:block lg:hidden w-[250%] absolute left-0 bottom-[60px]"
            />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 hidden sm:block lg:hidden w-[250%] absolute left-0 bottom-0"
            />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 hidden sm:block lg:hidden w-[250%] absolute left-0 -bottom-10"
            />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 w-full block sm:hidden absolute left-0 bottom-[60px]"
            />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 w-full block sm:hidden absolute left-0 bottom-0"
            />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 w-full block sm:hidden absolute left-0 -bottom-10"
            />
          </Link>
          <Link
            href="https://app.mintra.ai/collection/0x1e4a8c11c40f4097e40fb1dc45d8f575c53d1c08"
            target="_blank"
            className="group relative lg:border-x lg:border-slate-100 dark:border-slate-900"
          >
            <NftCard img="/pls-punks-v2.png" title="PLS Punks v2" />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 w-full block sm:hidden absolute left-0 bottom-[60px]"
            />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 w-full block sm:hidden absolute left-0 bottom-0"
            />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 w-full block sm:hidden absolute left-0 -bottom-10"
            />
          </Link>
          <Link
            href="https://app.mintra.ai/collection/0x13f3f9cc480093b444727bbbc430de89660434f5"
            target="_blank"
            className="group lg:border-l lg:border-slate-100 dark:border-slate-900"
          >
            <NftCard img="/chapter2pass.png" title=" Mintra Chapter 2 Pass" />
          </Link>
        </div>
        <div className="!grid sm:grid-cols-2 overflow-hidden grid-cols-1 lg:grid-cols-3 gap-10 relative">
          <span
            aria-hidden="true"
            className="border-t border-slate-100 dark:border-slate-900 block w-full absolute left-0 bottom-[60px]"
          />
          <Link
            href="https://app.mintra.ai/collection/0x4c8dd48784d4bcb1011a8027bdd83f7447411efd"
            target="_blank"
            className="group lg:border-r relative lg:border-slate-100 dark:border-slate-900"
          >
            <NftCard img="/mintra-punks.png" title=" Mintra Punks v1" />
            <span
              aria-hidden="true"
              className="border-r border-slate-100 dark:border-slate-900 hidden sm:block lg:hidden h-[250%] absolute right-0 top-0"
            />
            <span
              aria-hidden="true"
              className="border-r border-slate-100 dark:border-slate-900 hidden sm:block lg:hidden h-[250%] absolute -right-10 top-0"
            />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 hidden sm:block lg:hidden w-[250%] absolute left-0 bottom-[60px]"
            />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 hidden sm:block lg:hidden w-[250%] absolute left-0 bottom-0"
            />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 hidden sm:block lg:hidden w-[250%] absolute left-0 -bottom-10"
            />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 w-full block sm:hidden absolute left-0 bottom-[60px]"
            />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 w-full block sm:hidden absolute left-0 bottom-0"
            />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 w-full block sm:hidden absolute left-0 -bottom-10"
            />
          </Link>
          <Link
            href="https://app.mintra.ai/rewards"
            target="_blank"
            className="group relative lg:border-x lg:border-slate-100 dark:border-slate-900"
          >
            <NftCard img="/mintra-punks-v2.png" title=" Mintra Punks v2" />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 w-full block sm:hidden absolute left-0 bottom-[60px]"
            />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 w-full block sm:hidden absolute left-0 bottom-0"
            />
            <span
              aria-hidden="true"
              className="border-t border-slate-100 dark:border-slate-900 w-full block sm:hidden absolute left-0 -bottom-10"
            />
          </Link>
          <div className="group lg:border-l lg:border-slate-100 dark:border-slate-900" />
        </div>
      </Slider>
      <div className="border-t border-slate-100 dark:border-slate-900 grid grid-cols-2 overflow-hidden lg:grid-cols-3 gap-10">
        <button
          onClick={() => sliderRef?.current?.slickPrev()}
          className="border-r group justify-center overflow-hidden relative flex items-center gap-4 p-4 border-slate-100 dark:border-slate-900"
        >
          <span className="absolute top-0 right-0 rounded-full h-full w-14 bg-fuchsia-600/10 opacity-0 group-hover:opacity-100 dark:bg-fuchsia-600/15 group-hover:w-full group-hover:scale-150 transition-all duration-300" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 group-hover:-translate-x-4 transition-all duration-200"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
            />
          </svg>
          Prev
        </button>
        <div className="border-x lg:block hidden border-slate-100 dark:border-slate-900" />
        <button
          onClick={() => sliderRef?.current?.slickNext()}
          className="border-l flex items-center justify-center gap-4 relative overflow-hidden group border-slate-100 dark:border-slate-900"
        >
          <span className="absolute top-0 left-0 rounded-full h-full w-14 bg-fuchsia-600/10 opacity-0 group-hover:opacity-100 dark:bg-fuchsia-600/15 group-hover:w-full group-hover:scale-150 transition-all duration-300" />
          Next
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 group-hover:translate-x-4 transition-all duration-200"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
            />
          </svg>
        </button>
      </div>
    </section>
  );
}
