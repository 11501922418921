export function Button({
  children,
  dir = "left",
  onClick,
}: {
  children: React.ReactNode;
  dir?: "left" | "right";
  onClick?: () => void;
}) {
  return (
    <button
      className="group dark:text-slate-50 text-slate-950 relative"
      onClick={onClick}
    >
      {children}
      <span
        className={`w-10 group-hover:left-0 h-10 rounded-full -translate-x-1 transition-all duration-200 bg-fuchsia-600/10 dark:bg-fuchsia-600/15 absolute top-1/2 -translate-y-1/2 ${
          dir === "left" ? "left-1/2" : "-left-1/2"
        }`}
      />
    </button>
  );
}
