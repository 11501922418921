"use client";

import * as React from "react";

// @styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// @components
import Image from "next/image";
import Link from "next/link";
import Slider from "react-slick";
import { Heading, Button } from "@/components";

const CONTENT = [
  {
    backdrop: "Mintra.ai",
    title: "Pulsechain NFT Marketplace & Launchpad",
    desc: "Launched in 2023, Mintra is an on-chain, fee-sharing NFT marketplace & launchpad on Pulsechain mainnet.",
  },
  {
    backdrop: "LookIntoHex.com",
    title: "Quantitative HEX price analysis and models",
    desc: "Launched in 2021, LookIntoHex is a free set of HEX statistical data, charts, & dashboards.",
  },
  {
    backdrop: "LookIntoRH.com",
    title: "Pulsechain / PulseX Sacrifice Wallets Tracker",
    desc: "Launched in 2024, LookIntoRH tracks the 170,000+ ETH associated with the Pulsechain & PulseX sacrifice wallets.",
  },
  {
    backdrop: "Pulsechain .Community",
    title: "Pulsechain Community Gallery",
    desc: "Launched in 2024, Pulsechain.Community showcases links to hundreds of Pulsechain influencers & projects.",
  },
  {
    backdrop: "Lightbeam.xyz",
    title: "Pulsechain Block Explorer",
    desc: "Launching sometime in 2025, Lightbeam will be a robust block explorer for the Pulsechain network.",
  },
];

export function Projects() {
  const sliderRef = React.useRef<any>(null);
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const settings = {
    className: "center slider variable-width",
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    dots: false,
    swipe: true,
    autoplaySpeed: 3000,
    speed: 1000,
    swipeToSlide: true,
    beforeChange: (_: any, next: any) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section data-name="projects" className="overflow-hidden">
      <div className="max-w-5xl border-x dark:border-slate-900 border-slate-100 mx-auto py-8 md:pt-8 md:pb-12 lg:pt-12 lg:pb-16 border-b relative">
        <span className="select-none pointer-events-none border-l border-slate-100 dark:border-slate-900 hidden md:block h-[325%] absolute w-4 dark:bg-slate-950 bg-white -right-4 z-10 top-0" />
        <span className="select-none pointer-events-none border-r border-slate-100 dark:border-slate-900 hidden md:block h-[325%] absolute w-4 dark:bg-slate-950 bg-white -left-4 z-10 top-0" />
        <Heading backdrop="Projects">What I&apos;ve Built:</Heading>
      </div>
      <Slider
        ref={sliderRef}
        {...settings}
        className="outline-none focus-visible:outline-none"
      >
        {[
          { src: "/MintraHome.png", href: "https://app.mintra.ai" },
          { src: "/LookIntoHexHome.png", href: "https://lookintohex.com" },
          { src: "/LookIntoRHHome.png", href: "https://lookintorh.com" },
          { src: "/PulsechainCommunityHome.png", href: "https://pulsechain.community" },
          { src: "/LightbeamHome.png", href: "https://lightbeam.xyz" },

        ].map((el, key) => {
          return (
            <div
              key={key}
              style={{ width: 1024 }}
              className="w-full h-72 sm:h-96 md:h-[440px] lg:h-[540px] outline-none focus-visible:outline-none"
            >
              <Link href={el.href} target="_blank" rel="noopener noreferrer">
                <Image
                  src={el.src}
                  alt={`image-${key}`}
                  width={1920}
                  height={1920}
                  className="w-full h-full object-cover object-center"
                />
              </Link>
            </div>
          );
        })}
      </Slider>
      <div className="pt-10 pb-10 -mt-[6.5px] md:pt-16 md:pb-8 border dark:border-slate-900 border-slate-100 max-w-5xl mx-auto relative">
        <div className="flex items-center justify-between gap-8">
          <div className="hidden sm:block">
            <Button onClick={() => sliderRef?.current?.slickPrev()}>
              Prev
            </Button>
          </div>
          <div className="text-center mx-auto relative">
            <h2 className="text-slate-950 dark:text-slate-50 font-semibold text-lg sm:text-xl mb-2">
              {CONTENT[currentSlide].title}
            </h2>
            <p className="text-base dark:text-slate-400 text-slate-600 max-w-lg mx-auto [text-wrap:_balance]">
              {CONTENT[currentSlide].desc}
            </p>
            <span
              aria-hidden="true"
              aria-disabled="true"
              className="hidden select-none md:block md:text-6xl lg:text-8xl -translate-y-8 font-black dark:text-fuchsia-600/10 text-fuchsia-600/5"
            >
              {CONTENT[currentSlide].backdrop}
            </span>
          </div>
          <div className="hidden sm:block">
            <Button dir="right" onClick={() => sliderRef?.current?.slickNext()}>
              Next
            </Button>
          </div>
        </div>
        <div className="flex justify-center mt-10 items-center gap-16 sm:hidden">
          <Button onClick={() => sliderRef?.current?.slickPrev()}>Prev</Button>
          <Button dir="right" onClick={() => sliderRef?.current?.slickNext()}>
            Next
          </Button>
        </div>
      </div>
    </section>
  );
}
