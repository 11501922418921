import(/* webpackMode: "eager" */ "/vercel/path0/app/components/community.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/nfts.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/projects.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/navbar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/theme-toggle.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
